import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import './client.css'; // Ensure this points to your updated CSS file
import review2 from './review2.webp';
import review1 from './review1.webp';
import review3 from './DSS SVG.webp';

const reviews = [
  {
    id: 1,
    name: 'Hazel Rodriguez',
    title: 'HRR PR Consulting',
    review: '"Divine Software Systems were the best Web Design team we have ever worked with, creating exactly what we were looking for. Since then we have started our partnership!"',
    imageUrl: review1,
    alt: 'Hazel Rodriguez, HRR PR Consulting - Client Review',
    link: 'https://example.com/hazel-rodriguez'
  },
  {
    id: 2,
    name: 'Patrick Rodriguez',
    title: 'Farmers Insurance',
    review: '"Divine Software Systems created and designed our website, they helped boost our customer growth with their modern design and user-friendly designs."',
    imageUrl: review2,
    alt: 'Patrick Rodriguez, Farmers Insurance - Client Review',
    link: 'https://example.com/patrick-rodriguez'
  },
  {
    id: 3,
    name: 'Em Jimenez',
    title: 'Sbovacom',
    review: '"Divine Software Systems created our website and a system to manage applications, and both are incredible. We’ve seen a big jump in customer inquiries and sales since launch!"',
    imageUrl: review3,
    alt: 'Em Jimenez, Sbovacom - Client Review',
    link: 'https://example.com/katelyn-lopez'
  }
  
];

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

const ClientReviews = () => {
  return (
    <Container fluid className="p-0 m-0 client-review-container">
      <Row className="justify-content-center align-items-center h-100 my-5">
        <Col md={12} className="text-center">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={itemVariants}
            transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
          >
            <h2 className="testimonial-header test-help mb-2 h1">Our Testimonials</h2>
            <p className="testi-1 h5">Hear what our satisfied clients have to say about us.</p>
          </motion.div>
        </Col>
        {reviews.map((review) => (
          <Col lg={4} md={6} sm={12} key={review.id} className="client-review-col">
            <motion.div
              className="testimonial"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={itemVariants}
              transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
            >
              <div className="testimonial-content">
                <div className="testimonial-icon">
                  <i className="fa fa-quote-left"></i>
                </div>
                <p className="description">
                  {review.review}
                </p>
              </div>
              <div className="testimonial-info">
                <div className="testimonial-text">
                  <h3 className="title">{review.name}</h3>
                  <span className="post">{review.title}</span>
                </div>
                <img
                  src={review.imageUrl}
                  alt={review.alt}
                  className="client-review-profile-img"
                />
              </div>
            </motion.div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

const BackgroundWithTestimonials = () => {
  return (
    <div className="client-static-bg-container">
      <div className="client-my-static-bg-row">
        <ClientReviews />
      </div>
    </div>
  );
};

export default BackgroundWithTestimonials;
