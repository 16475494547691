import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import './cards.css';
import img1 from './pathlinkdss.webp';
import img2 from './rcss.webp';

const images = [
  {
    src: img1,
    description: 'PathLink',
    title: 'Logistics & Shipment Management',
    link: 'https://www.pathlink.app/' // External link
  },
  {
    src: img2,
    description: 'RCS Logistics PH',
    title: 'Leading Logistics Company in PH',
    link: 'https://www.rcslogisticsph.com/'  // External link
  }
  
];

const itemVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
};

const CardsComponent = () => {
  return (
    <div className="custom-animate-background">
      <Container fluid className="py-5 my-5">
        <Row className="mb-4 text-center">
          <Col>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={itemVariants}
              transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
            >
              <h2 className="comp-title testimonial-header mb-1">Explore Some of Our Recent<br></br>Work For Our Clients</h2>
              <p className='comp-desc1 sub-header1'>Experience directly how we have assisted businesses like yours <br>
              </br> in prospering within the digital realm.</p>
            </motion.div>
          </Col>
        </Row>
        <Row>
          {images.map((image, index) => (
            <Col md={6} key={index} className="mb-4">
              <a href={image.link} 
                 target={image.link.startsWith('http') ? '_blank' : '_self'} 
                 rel={image.link.startsWith('http') ? 'noopener noreferrer' : undefined}
                 style={{ textDecoration: 'none', color: 'inherit' }} // Inline style to remove underline and inherit text color
              >
                <img src={image.src} alt={image.title} className="img-fluid" />
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.2 }}
                  variants={itemVariants}
                  transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
                >
                  <div className="clickable-container rounded-pill text-left mt-3" style={{ textDecoration: 'none' }}>
                    <div className="text-block" style={{ textDecoration: 'none' }}>
                      <h4 className='comp-head' style={{ textDecoration: 'none' }}>{image.description}</h4>
                      <p className='comp-text' style={{ textDecoration: 'none' }}>{image.title}</p>
                    </div>
                    <i className="circle-arrow"></i>
                  </div>
                </motion.div>
              </a>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default CardsComponent;
