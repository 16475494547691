import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faLaptopCode, faMobileAlt, faBullhorn, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './wwo.css';


const services = [
  {
    icon: faLaptopCode,
    title: 'Web Development',
    description: 'Revolutionize your online identity with our tailored web development services. We excel in crafting engaging websites and custom web applications, ensuring cutting-edge design and seamless functionality. Elevate your digital presence with DSS today!',
    link: '/website-applications',
    hiddenDescription: 'Explore our web design services to revolutionize your online presence.'
  },
  {
    icon: faMobileAlt,
    title: 'Mobile Application',
    description: 'Elevate your digital strategy with our mobile app development services. Tailored for iOS, Android, and cross-platform needs, we deliver apps that combine high performance with intuitive design, aligning with your business goals to engage users effectively.',
    link: '/mobile-app',
    hiddenDescription: 'Discover our mobile app development services for high-performance, user-friendly apps.'
  },
  {
    icon: faCode,
    title: 'Custom Software',
    description: 'Empower your business with tailored software solutions. From custom management systems to database creation and workflow automation, we design and develop software that meets your specific needs, optimizing efficiency and scalability.',
    link: '/contact-us',
    hiddenDescription: 'Learn about our custom software solutions to streamline your operations.'
  },
  {
    icon: faBullhorn,
    title: 'Digital Marketing',
    description: 'Boost your online presence and captivate your audience with our customized strategies. We provide social media management, SEO-driven blog posts, bespoke social media designs, and email newsletters to ensure your business shines online.',
    link: '/digital-marketing',
    hiddenDescription: 'Find out more about our digital marketing services to boost your online presence.'
  }
];

const cardStyles = {
  padding: '1rem',
  border: 'none',
  marginBottom: '1rem',
  transition: '0.5s all ease',
  textAlign: 'left',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};

const cardBlockStyles = {
  position: 'relative',
  textAlign: 'left',
  zIndex: 1,
  flex: '1'
};

const titleStyles = {
  textAlign: 'left',
  fontWeight: 'bold'
};

const textStyles = {
  textAlign: 'left',
  fontSize: '1.125rem'
};

const WWO = () => {
  // Animation variant
  const itemVariants = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 }
  };

  return (
    <section id="what-we-do" className="animate-background">
      <Container fluid>
        <Row className="header-row text-center mb-4">
          <Col xs={12} >
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.2 }}
              variants={itemVariants}
              transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
            >
              <h2 className="comp-title testimonial-header services-header11 mb-2 h1">Our Services</h2>
              <p className="wwo-header-text sub-header1">We provide technical solutions designed to enhance your business needs.</p>
            </motion.div>
          </Col>
        </Row>
        <Row className="mt-5">
          {services.map((service, index) => (
            <Col xs={12} sm={12} md={12} lg={12} xl={6} key={index}>
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.2 }}
                variants={itemVariants}
                transition={{ delay: 0.1, duration: 0.4, ease: "easeInOut" }}
              >
                <Card style={cardStyles} className="custom-wwo-card">
                  <div className="circle-animation"></div>
                  <Card.Body className={`card-block block-${index + 1}`} style={cardBlockStyles}>
                    <h3 className="card-title" style={titleStyles}>{service.title}</h3>
                    <p className="card-text" style={textStyles}>{service.description}</p>
                    <a href={service.link} className="learn-more-circle1">
                      <span className="learn-more-text">Learn More</span>
                      <FontAwesomeIcon className='learn-more-arrow' icon={faArrowRight} />
                      <span className="visually-hidden">{service.hiddenDescription}</span>
                    </a>
                  </Card.Body>
                </Card>
              </motion.div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default WWO;
