import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { motion } from 'framer-motion';
import img1 from './paperless.webp';
import img2 from './clienttrack.webp';
import img3 from './dashboardtrack.webp';
import img4 from './devices.webp';
import img5 from './clientside.webp';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const PathwaySections = () => {
    return (
        <Container className="my-5">
            <div className="custom-services-subheader">
                <h3>Transforming Logistics</h3>
                <span className="custom-line-icon"></span>
            </div>

            <div className="custom-wwo-section mt-3">
                <h2>Why Choose PathLink?</h2>
                
            </div>

            {/* Eliminate Paper-Based Processes */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img1} alt="Eliminate Paper-Based Processes" fluid className="divine-img" />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>Eliminate Paperwork While Reducing Errors</h2>
                        <p className="text-center">
                            <strong>Reduce errors by up to 70%</strong> with PathLink’s fully digital system. Say goodbye to paper-based processes and save time with automated workflows, ensuring faster decision-making and easy access to critical information across departments.
                        </p>
                    </motion.div>
                </Col>
            </Row>

            {/* Real-Time Shipment Tracking */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>Real-Time Shipment Tracking for Transparency</h2>
                        <p className="text-center">
                            <strong>89% of clients prefer real-time tracking</strong>. With PathLink’s live update system, you can provide complete shipment visibility, keeping both clients and teams informed from shipment creation to delivery completion.
                        </p>
                    </motion.div>
                </Col>
                <Col lg={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                    <Image src={img2} alt="Real-Time Shipment Tracking" fluid className="divine-img" />
                </Col>
            </Row>

            {/* Enhance Supply Chain Visibility */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img3} alt="Enhance Supply Chain Visibility" fluid className="divine-img" />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>Achieve Complete Supply Chain Visibility</h2>
                        <p className="text-center">
                            <strong>85% of businesses</strong> report improved supply chain visibility using centralized dashboards. PathLink’s platform offers full shipment progress insights, resource allocation data, and team performance metrics to support data-driven decision-making.
                        </p>
                    </motion.div>
                </Col>
            </Row>

            {/* Cost-Effective and Hardware-Free */}
            <Row className="align-items-center mb-custom section-light-gray-1">
                <Col lg={{ span: 6, order: 'first' }} xs={{ span: 12, order: 'last' }}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>Cost-Effective Without Hardware Needs</h2>
                        <p className="text-center">
                            <strong>Save up to 50% on operational costs</strong> by eliminating the need for GPS devices and scanners. PathLink’s web-based system operates on any internet-connected device, making it ideal for businesses seeking scalable solutions.
                        </p>
                    </motion.div>
                </Col>
                <Col lg={{ span: 6, order: 'last' }} xs={{ span: 12, order: 'first' }}>
                    <Image src={img4} alt="Cost-Effective and Hardware-Free" fluid className="divine-img" />
                </Col>
            </Row>

            {/* Fully Customizable System */}
            <Row className="divine-sections align-items-center mb-custom section-light-gray-1">
                <Col lg={6} xs={12}>
                    <Image src={img5} alt="Fully Customizable System" fluid className="divine-img" />
                </Col>
                <Col lg={6} xs={12}>
                    <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeUpVariants}>
                        <h2>Customizable Solutions for Your Business</h2>
                        <p className="text-center">
                            <strong>92% of businesses</strong> reported increased efficiency with customizable systems. PathLink allows you to tailor branding, dashboards, and workflows to match your business processes while integrating seamlessly with existing tools.
                        </p>
                    </motion.div>
                </Col>
            </Row>
        </Container>
    );
};

export default PathwaySections;
