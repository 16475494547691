import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faHandshake, faHeadset, faPhone, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from 'framer-motion';

const PathwayContact = () => {
  // Updated state: removed subject and added shipments and company
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    shipments: '',
    message: ''
  });

  const handleSubmitVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const formAppearVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.3,
        duration: 0.5,
        ease: 'easeInOut'
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    try {
      const response = await fetch('https://formspree.io/f/mrbgdgnq', {
        method: 'POST',
        body: new FormData(form),
        headers: {
          'Accept': 'application/json'
        },
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  return (
    <>
      <Container className="mt-5 py-5 bg-light border-top border-bottom">
        <Row className="justify-content-center">
          <Col md={12} className="text-center mb-4">
            <h1 className="display-45 new-add" style={{ fontWeight: '550' }}>
              Why Choose PathLink?
            </h1>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faRocket} size="3x" className="mb-2" />
            <h3>Customizable for Your Business</h3>
            <p>
              PathLink adapts to your unique workflows, ensuring a seamless fit into your
              operations. Tailor everything from dashboards to workflows to match your needs.
            </p>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faHandshake} size="3x" className="mb-2" />
            <h3>Cost-Effective and Hardware-Free</h3>
            <p>
              No need for expensive GPS devices or scanners—PathLink works on any internet-enabled
              device, saving you money while maximizing efficiency.
            </p>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faHeadset} size="3x" className="mb-2" />
            <h3>Real-Time Transparency</h3>
            <p>
              88% of customers demand real-time tracking. With PathLink, you’ll provide live updates
              that keep clients informed and build trust.
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid className="custom-container help-me">
        <Row className="justify-content-md-center">
          <Col sm={12} lg={6} xl={6} xxl={6} className="left-column">
            <h2 className="homeform-heading">Get in Touch</h2>
            <p className="contact-text1 small-text">
              We’re here to help you revolutionize your logistics operations with tailored solutions.
              Contact us today to learn how PathLink can transform your business.
            </p>
            <div className="contact-info">
              
              <p className="contact-detail-item email-detail">
                <span className="icon-circle">
                  <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                </span>
                <span className="contact-detail">hello@divinesoftwaresystems.com</span>
              </p>
              <p className="contact-detail-item">
                <span className="icon-circle">
                  <FontAwesomeIcon icon={faGlobe} className="contact-icon" />
                </span>
                <span className="contact-detail">
                  <a
                    href="https://www.pathlink.app/"
                    style={{ textDecoration: 'underline', color: 'inherit' }}
                  >
                    https://www.pathlink.app/
                  </a>
                </span>
              </p>
            </div>
          </Col>
          <Col sm={12} lg={6} xl={6} xxl={6}>
            <AnimatePresence mode="wait">
              {submitted ? (
                <motion.div
                  key="thank-you"
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={handleSubmitVariants}
                  className="paper-like-container text-center thank-you-message"
                >
                  <h3 className="thank-you-title">Thank you for reaching out!</h3>
                  <p className="thank-you-text">
                    Your message has been successfully submitted. We’ll respond within 24-48 hours.
                  </p>
                </motion.div>
              ) : (
                <motion.div
                  key="form"
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.3 }}
                  variants={formAppearVariants}
                  className="paper-like-container"
                >
                  <h2 className="form-header">Book Demo Today</h2>
                  <Form className="centered-form" onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} md={6} className="mb-3">
                        <Form.Group>
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Full Name"
                            className="paper-like-input"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} className="mb-3">
                        <Form.Group>
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Phone Number"
                            className="paper-like-input"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={6} className="mb-3">
                        <Form.Group>
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Email Address"
                            className="paper-like-input"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6} className="mb-3">
                        <Form.Group>
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Company Name"
                            className="paper-like-input"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3">
                      <Form.Label>Approx. Shipments per Month or Total Orders*</Form.Label>
                      <Form.Control
                        as="select"
                        className="paper-like-input"
                        name="shipments"
                        value={formData.shipments}
                        onChange={handleChange}
                      >
                        <option value="">
                          Approx. Shipments per Month or Total Orders*
                        </option>
                        <option value="1-50">1-50</option>
                        <option value="51-100">50-100</option>
                        <option value="101-250">101-250</option>
                        <option value="251-500">251-500</option>
                        <option value="500+">500+</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        placeholder="Message"
                        className="paper-like-input"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit" style={{ fontWeight: '550', minWidth: '100px', marginBottom: '-10px' }}>
                      Submit
                    </Button>
                  </Form>
                </motion.div>
              )}
            </AnimatePresence>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PathwayContact;
