import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faRocket, 
  faHandshake, 
  faHeadset 
} from '@fortawesome/free-solid-svg-icons';

const InsuranceContact = () => {
  return (
    <>
      <Container className="mt-5 py-5 bg-light border-top border-bottom">
        <Row className="justify-content-center">
          <Col md={12} className="text-center mb-4">
            <h1 className="display-45 new-add" style={{ fontWeight: '550' }}>Why Choose Us?</h1>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faRocket} size="3x" className="mb-2" />
            <h3>Maximize Lead Capture</h3>
            <p>Our websites ensure all quote requests are sent directly to you, helping you convert leads without the competition from other agents.</p>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faHandshake} size="3x" className="mb-2" />
            <h3>Customized Solutions</h3>
            <p>Get a fully tailored website optimized for your insurance business, boosting your visibility and making you easy to find online.</p>
          </Col>
          <Col md={4} className="text-center mb-3 divine-para">
            <FontAwesomeIcon icon={faHeadset} size="3x" className="mb-2" />
            <h3>Dedicated Support</h3>
            <p>Access 24/7 support to help you make the most of your website, ensuring smooth operation and maximum impact.</p>
          </Col>
        </Row>
      </Container>

      
    </>
  );
};

export default InsuranceContact;
