import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Accordion, Form, Button } from 'react-bootstrap';
import { motion, useInView, useAnimation } from 'framer-motion';
import Slider from 'react-slick';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faGoogle, faAndroid, faApple, faMicrosoft, faAws, faAngular } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMapMarkerAlt, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import './mobileapp.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AnimatePresence } from 'framer-motion';

import mobileGallery1 from './11.webp';
import mobileGallery2 from './12.webp';
import mobileGallery3 from './8.webp';
import mobileGallery4 from './10.webp';
import mobileGallery5 from './5.webp';
import mobileGallery6 from './6.webp';
import mobileGallery7 from './7.webp';
import mobileGallery8 from './9.webp';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';


gsap.registerPlugin(ScrollTrigger);

function MobileApp() {
  const navigate = useNavigate();
  const navigateToMobilePricing = () => navigate('/mobile-app-prices');

  const images = [
    mobileGallery5,
    mobileGallery1,
    mobileGallery2,
    mobileGallery3,
    mobileGallery7,
    mobileGallery8,
    mobileGallery6,
    mobileGallery4,
  ];

  return (
    <>
      <Header navigateToMobilePricing={navigateToMobilePricing} />
      <Features />
      <InfiniteCarousel />
      <RecentWork images={images} />
      <FAQSection />
      <HomeContact />
    </>
  );
}

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 0.4,
    },
  },
};

const wordVariants = {
  hidden: (direction) => ({
    opacity: 0,
    x: direction === 'left' ? -60 : 60,
  }),
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const iconVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.3,
      type: 'spring',
      stiffness: 70,
      damping: 10,
    },
  },
};

const Header = ({ navigateToMobilePricing }) => {
  const component = useRef();
  const scrollDownRef = useRef();
  const controls = useAnimation();
  const ref = useRef(null);
  const inView = useInView(ref);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.to('.icon-animation', {
        y: -10,
        repeat: -1,
        yoyo: true,
        ease: 'power1.inOut',
        duration: 1,
      });

      
    }, component);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={component}>
      <section className="header-section about-container">
        <Container className="text-center">
          <motion.div
            className="text-container"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <motion.div className="word" custom="left" variants={wordVariants}>
              MOBILE
            </motion.div>
            <motion.div className="word" custom="right" variants={wordVariants}>
              <span>
                <span className='highlight'>D</span>EVELOPMENT
              </span>
            </motion.div>
            <motion.div className="word" custom="left" variants={wordVariants}>
              <span>
              <span className='highlight'>S</span>ERVICE<span className='highlight'>S</span>
              </span>
            </motion.div>
          </motion.div>
          <motion.div
            className="learn-more"
            initial="hidden"
            animate="visible"
            variants={iconVariants}
            ref={scrollDownRef}
          >
            <div className="circle-icon icon-animation">
              <FontAwesomeIcon icon={faArrowDown} size="lg" />
            </div>
            <span className="who-we">LEARN MORE</span>
          </motion.div>
        </Container>
      </section>
    </div>
  );
};

const Features = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <section className="py-5" id="features">
      <Container className="my-5">
        <div className="custom-services-subheader">
          <h3>Key Features</h3>
          <span className="custom-line-icon"></span>
        </div>
        <div className="custom-wwo-section mt-3 mb-5 feature-accordian-1">
          <h2>Mobile Development</h2>
        </div>
      </Container>

      <Container className="my-5">
        <motion.div
          className="py-5 scrollable-section"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.6, ease: "easeOut" }}
          viewport={{ once: true, amount: 0.2 }}
        >
          <Accordion activeKey={activeKey} className="feature-accordion">
            {accordionItems.map((item, index) => (
              <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header onClick={() => handleToggle(index.toString())}>
                  <span>{item.title}</span>
                  <div className="accordion-icon">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="icon-circle"
                      style={{
                        transform: activeKey === index.toString() ? 'rotate(45deg)' : 'rotate(0deg)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  </div>
                </Accordion.Header>
                <Accordion.Body style={{ textAlign: 'left' }}>
                  <p>{item.description}</p>
                  <ul>
                    {item.points.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </motion.div>
      </Container>
    </section>
  );
};

const accordionItems = [
  {
    title: 'Client Requirements Understanding',
    description: 'Ensuring we capture every detail of what you need:',
    points: [
      'Thorough engagement to ensure all needs are met.',
      'Utilization of tools to define and document precise requirements.',
      'Continuous feedback loops to refine the app throughout development.',
    ],
  },
  {
    title: 'User Interface & User Experience',
    description: 'Creating intuitive and aesthetically pleasing app environments:',
    points: [
      'Focus on user-friendly design principles to enhance app usability.',
      'Iterative design process with prototyping and user testing.',
      'Adherence to accessibility standards to cater to all users.',
    ],
  },
  {
    title: 'Technical Architecture & Development',
    description: 'Building robust and scalable app solutions:',
    points: [
      'Select optimal technologies tailored to the app’s needs and future growth.',
      'Ensure seamless integration of front-end and back-end systems.',
      'Focus on maintainability and scalability in coding practices.',
    ],
  },
  {
    title: 'Data Management & Security',
    description: 'Securing data and ensuring compliance with legal standards:',
    points: [
      'Implement cutting-edge security measures to protect user data.',
      'Compliance with international data protection regulations.',
      'Regular updates and audits to maintain the highest level of security.',
    ],
  },
  {
    title: 'Quality Assurance & Testing',
    description: 'Guaranteeing a seamless and bug-free user experience:',
    points: [
      'Comprehensive testing strategies to detect and solve issues early.',
      'Usage of automated and manual testing tools to ensure broad coverage.',
      'Regular performance optimization to keep the app running smoothly.',
    ],
  },
  {
    title: 'Maintenance & Support',
    description: 'Ensuring your mobile app remains up-to-date and fully functional:',
    points: [
      'Regular updates to keep your app compatible with the latest OS versions.',
      'Proactive monitoring to identify and resolve issues before they impact users.',
      'Swift bug fixes and performance optimizations for seamless user experience.',
      'Consistent and transparent communication regarding maintenance activities and updates.',
    ],
  },
];


const InfiniteCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 2300,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const technologies = [
    { 
      name: 'React Native', 
      description: 'We use React Native to build cross-platform mobile apps. Its reusable components allow us to create efficient, high-performing apps for both iOS and Android. Fast refresh ensures instant updates during development.',
      icon: faReact
    },
    { 
      name: 'Flutter', 
      description: 'Our team leverages Flutter to develop visually stunning native apps. With hot reload, we can experiment and build UIs quickly, ensuring a smooth development process and a high-quality end product.',
      icon: faGoogle
    },
    { 
      name: 'Kotlin', 
      description: 'For Android development, we utilize Kotlin. Its concise syntax and interoperability with Java allow us to write less code and maintain high performance, ensuring robust and maintainable applications.',
      icon: faAndroid
    },
    { 
      name: 'Swift', 
      description: 'Swift is our choice for iOS app development. This powerful language enables us to write clean, expressive code quickly, which results in fast and reliable iOS applications.',
      icon: faApple
    },
    { 
      name: 'Xamarin', 
      description: 'With Xamarin, we create native apps for multiple platforms using a single codebase. This allows us to share code across iOS and Android, ensuring consistent performance and user experience.',
      icon: faMicrosoft
    },
    { 
      name: 'Ionic', 
      description: 'Ionic helps us build hybrid mobile apps efficiently. It integrates perfect with frameworks like Angular, React, and Vue, which allows us to develop apps for multiple different platforms with one codebase.',
      icon: faAngular
    },
    { 
      name: 'AWS', 
      description: 'We utilize AWS for scalable, secure mobile app development. With tools like AWS Amplify and AWS AppSync, we build apps that scale effortlessly and ensure high availability and performance.',
      icon: faAws
    },
  ];

  return (
    <section className="carousel-section">
      <div className="carousel-background-text">
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
        <div className="scrolling-text">DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS DIVINE SOFTWARE SYSTEMS</div>
      </div>
      <div className="tech-can">
        <div className="carousel-header">
          <h2>TECHNOLOGIES WE USE</h2>
          <p>We offer innovative services based on your business needs:</p>
        </div>
        <Slider {...settings}>
          {technologies.map((tech, index) => (
            <div key={index} className="carousel-card">
              <div className="carousel-card-content">
                <FontAwesomeIcon icon={tech.icon} size="3x" />
                <h3>{tech.name}</h3>
                <p>{tech.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

const RecentWork = ({ images }) => {
  const containerRefTop = useRef(null);
  const containerRefBottom = useRef(null);
  const isInViewTop = useInView(containerRefTop, { once: true });
  const isInViewBottom = useInView(containerRefBottom, { once: true });
  const lastScrollY = useRef(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (isMobile) return;

      const currentScrollY = window.scrollY;

      if (containerRefTop.current && containerRefBottom.current) {
        if (currentScrollY > lastScrollY.current) {
          containerRefTop.current.scrollLeft += 2;
          containerRefBottom.current.scrollLeft -= 2;
        } else {
          containerRefTop.current.scrollLeft -= 2;
          containerRefBottom.current.scrollLeft += 2;
        }
      }
      lastScrollY.current = currentScrollY;
    };

    if (isInViewTop || isInViewBottom) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isInViewTop, isInViewBottom, isMobile]);

  useEffect(() => {
    if (containerRefBottom.current) {
      containerRefBottom.current.scrollLeft = containerRefBottom.current.scrollWidth; // Start at the far right
    }
  }, []);

  return (
    <section className="py-5 work-bg scrollable-section">
      <div className="full-width">
        <div className="p-5 rounded-3">
          <div className="custom-services-subheader">
            <h1 className="display-4 explore-head">Explore Our Recent Projects</h1>
            <p className='large-screen-hide'>Take a look at some of the amazing work we've done for our clients. We pride ourselves on delivering high-quality projects that drive results.</p>
          </div>
          <div className="image-gallery-container" ref={containerRefTop}>
            {images.map((src, index) => (
              <div key={index} className="image-container">
                <img src={src} alt={`Mobile app development project ${index}`} className="gallery-image" />
              </div>
            ))}
          </div>
          <div className="image-gallery-container mt-2" ref={containerRefBottom}>
            {images.map((src, index) => (
              <div key={index} className="image-container">
                <img src={src} alt={`Mobile app development project ${index}`} className="gallery-image" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQSection = () => (
  <motion.section 
    className="py-5 faq-dark-bg" 
    id="faq" 
    initial={{ opacity: 0, y: 50 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.2, duration: 0.6, ease: "easeOut" }}
    viewport={{ once: true, amount: 0.3 }}
  >
    <div className="container px-5">
      <div className="custom-services-subheader">
        <h3>FAQ Section</h3>
        <span className="custom-line-icon"></span>
      </div>
      <div className="custom-wwo-section mt-3 faq-section-1">
        <h2 className='faq-all'>Frequently Asked Questions</h2>
      </div>
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingOne">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="false" aria-controls="collapseOne">
              What is the process for developing a mobile app with your company?
            </button>
          </h3>
          <div id="collapseOne" className="accordion-collapse collapse"
               aria-labelledby="headingOne">
            <div className="accordion-body">
              Our development process is customized to your needs, involving requirement gathering, design, development, testing, and deployment. We ensure constant communication to align the final product with your expectations.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingtwo">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsetwo"
                    aria-expanded="false" aria-controls="collapsetwo">
              How do you ensure the security and privacy of our data?
            </button>
          </h3>
          <div id="collapsetwo" className="accordion-collapse collapse"
               aria-labelledby="headingtwo">
            <div className="accordion-body">
              We employ our most stringent security measures to ensure the utmost protection of your information and user data. Using the most recent security measures at all times.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingthree">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsethree"
                    aria-expanded="false" aria-controls="collapsetwo">
              What technologies do you use for mobile app development?
            </button>
          </h3>
          <div id="collapsethree" className="accordion-collapse collapse"
               aria-labelledby="headingthree">
            <div className="accordion-body">
              We use a variety of technologies tailored to project needs, including native iOS and Android development, and cross-platform solutions like React Native and Flutter for optimal performance and user experience.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingfour">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsefour"
                    aria-expanded="false" aria-controls="collapsefour">
              Can you handle both the design and development of the app?
            </button>
          </h3>
          <div id="collapsefour" className="accordion-collapse collapse"
               aria-labelledby="headingfour">
            <div className="accordion-body">
              Yes, our team is equipped to manage all aspects from initial design to final development and deployment, ensuring a cohesive and seamless process.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h3 className="accordion-header" id="headingfive">
            <button className="accordion-button collapsed faq-button" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapsefive"
                    aria-expanded="false" aria-controls="collapsefive">
              How can I get a quote for my mobile app project?
            </button>
          </h3>
          <div id="collapsefive" className="accordion-collapse collapse"
               aria-labelledby="headingfive">
            <div className="accordion-body">
              To receive a customized quote, click the 'Mobile Development' pricing page on the navbar or contact us directly through our Contact Us page. We are eager to assist you in bringing your app idea to life.
            </div>
          </div>
        </div>
      </div>
    </div>
  </motion.section>
);

const HomeContact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleSubmitVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  const formAppearVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: 0.3, duration: 0.5, ease: 'easeInOut' },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    try {
      const response = await fetch('https://formspree.io/f/xrgnwzrj', {
        method: 'POST',
        body: new FormData(form),
        headers: { Accept: 'application/json' },
      });

      const data = await response.json();
      if (data.ok) {
        setSubmitted(true);
      } else {
        alert('Oops! There was a problem with your submission.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error sending message.');
    }
  };

  return (
    <Container fluid className="custom-container help-me">
      <Row className="justify-content-md-center">
        <Col sm={12} lg={6} xl={6} xxl={6} className="left-column">
          <h2 className="homeform-heading">Get in Touch</h2>
          <p className="contact-text1 small-text">
            We are a skilled team delivering custom websites, apps, graphic designs, and marketing solutions for your business.
          </p>
          <div className="contact-info">
           
            <p className="contact-detail-item email-detail">
              <span className="icon-circle">
                <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
              </span>
              <span className="contact-detail">hello@divinesoftwaresystems.com</span>
            </p>
            <p className="contact-detail-item">
              <span className="icon-circle">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
              </span>
              <span className="contact-detail">
                US Office:{' '}
                <a
                  href="https://www.google.com/maps/place/Divine+Software+Systems+LLC/@37.5763866,-121.979915,17z/data=!3m1!4b1!4m6!3m5!1s0x808fc13cb689ed09:0x2abc072afed6bcb8!8m2!3d37.5763866!4d-121.9773401!16s%2Fg%2F11y5l0w8my?entry=ttu"
                  style={{ textDecoration: 'underline', color: 'inherit' }}
                >
                  Fremont, CA
                </a>
              </span>
            </p>
            <p className="contact-detail-item">
              <span className="icon-circle">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="contact-icon" />
              </span>
              <span className="contact-detail">
                PH Office:{' '}
                <a
                  href="https://www.google.com/maps/place/BM+ONE+office+and+commercial+building/@14.4916087,120.9937898,17z/data=!4m16!1m9!3m8!1s0x3397cff3448fe381:0xf4f092d112e2d74f!2sBM+ONE+office+and+commercial+building!8m2!3d14.4916041!4d120.9937636!9m1!1b1!16s%2Fg%2F11txlr0tdy?entry=ttu"
                  style={{ textDecoration: 'underline', color: 'inherit' }}
                >
                  Manila, PH
                </a>
              </span>
            </p>
          </div>
        </Col>
        <Col sm={12} lg={6} xl={6} xxl={6}>
          <AnimatePresence mode="wait">
            {submitted ? (
              <motion.div
                key="thank-you"
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={handleSubmitVariants}
                className="paper-like-container text-center thank-you-message"
              >
                <h3 className="thank-you-title">Thank you for reaching out!</h3>
                <p className="thank-you-text">
                  Your message has been successfully submitted.
                </p>
                <p className="thank-you-text">
                  We appreciate your interest and will get back to you promptly.
                </p>
                <div className="email-response">
                  <h4 className="response-title">In the meantime, here's what to expect:</h4>
                  <ul className="response-list">
                    <li>Confirmation email has been sent to your provided address.</li>
                    <li>Your inquiry is being reviewed by our team of experts.</li>
                    <li>We will respond to your message within 24-48 hours.</li>
                  </ul>
                </div>
              </motion.div>
            ) : (
              <motion.div
                key="form"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
                variants={formAppearVariants}
                className="paper-like-container"
              >
                <h2 className="form-header">Make an Appointment</h2>
                <Form className="centered-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          className="paper-like-input"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                      <Form.Group>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Phone Number"
                          className="paper-like-input"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      className="paper-like-input"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>App Development Service</Form.Label>
                    <Form.Control
                      as="select"
                      className="paper-like-input"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                    >
                      <option value="">Select App Service</option>
                      <option value="iOS App Development">iOS App Development</option>
                      <option value="Android App Development">Android App Development</option>
                      <option value="Hybrid App Development">Hybrid App Development</option>
                      <option value="Cross-Platform App Development">Cross-Platform App Development</option>
                      <option value="App UI/UX Design">App UI/UX Design</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Please provide details about your app development needs."
                      className="paper-like-input"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    style={{ fontWeight: '550', minWidth: '100px', marginBottom: '-10px' }}
                  >
                    Submit
                  </Button>
                </Form>
              </motion.div>
            )}
          </AnimatePresence>
        </Col>
      </Row>
    </Container>
  );
};

export default MobileApp;
