import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

const PathwayFeatures = () => {
    const features = [
        {
            title: "Customizable for Your Operations",
            description: "PathLink adapts to your workflows, allowing you to tailor processes, roles, and dashboards for a perfect operational fit.",
            iconName: "bi-tools"
        },
        {
            title: "No Expensive Hardware Required",
            description: "Access PathLink on any internet-enabled device without the need for costly GPS units or specialized scanners.",
            iconName: "bi-laptop"
        },
        {
            title: "Go Paperless with Digital Efficiency",
            description: "Eliminate paper-based workflows and reduce manual errors with a fully digital system that streamlines operations.",
            iconName: "bi-file-earmark-text"
        },
        {
            title: "Real-Time Shipment Tracking Visibility",
            description: "Provide live updates on shipment progress, delays, and milestones to keep clients informed and build trust.",
            iconName: "bi-clock"
        },
        {
            title: "Scalable, Secure, and Reliable",
            description: "Built for growth, PathLink scales with your business while safeguarding sensitive data with advanced encryption.",
            iconName: "bi-shield-lock"
        }
    ];

    return (
        <div className="container" style={{ marginTop: '5rem', marginBottom: '5rem' }}>
            <div className="custom-services-subheader">
                <h3>PathLink Features</h3>
                <span className="custom-line-icon"></span>
            </div>

            <div className="custom-wwo-section mt-3 mb-5">
                <h2>Top Five System Features</h2>
                <p className="insurance-feat">
                PathLink is your trusted Transportation Management System partner, offering a customizable platform with built-in Track and Trace to eliminate costly hardware, enhance transparency, and streamline logistics operations.
</p>
            </div>

            <Row xs={1} md={2} lg={5} className="g-4">
                {features.map((feature, idx) => (
                    <Col key={idx}>
                        <Card className="divine-feature-card">
                            <Card.Body>
                                <i className={`bi ${feature.iconName} divine-feature-icon mb-2`} aria-hidden="true"></i>
                                <Card.Title className="about-feature-cards">{feature.title}</Card.Title>
                                <Card.Text>{feature.description}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default PathwayFeatures;
